import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from "react-router-dom";
import { LEFTRIGHT_PADDING } from "../config/style.config";
//service
import {fetchPermit,deletePermit} from '../services/permit.service'
import { deleteFileByName } from '../services/httpApiTracking';
///svg
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import BluePlus from '../svgs/permit/BluePlus'
import NavGlass from '../svgs/NavGlass'
import SearchMagGlass from '../svgs/permit/SearchMagGlass'
import ChartChainLink from '../svgs/ChartChainLink'
import BlueThreeDot from '../svgs/permit/BlueThreeDot'
import BlueDownloadSvg from '../svgs/tracking/BlueDownloadSvg'
import SmallHeader from '../svgs/tracking/SmallHeader'
import GreyBin from '../svgs/permit/GrayBin'
import DownArrow from '../svgs/map/DownArrow'
import WhiteCloseSvg from '../svgs/WhiteCloseSvg'
//componets
import PermitStatusPie from '../components/permit/PermitStatusPie'
import NoPermssion from './NoPermission'
import Dropdown from '../components/DropdownMenu'
import PageSelector from '../components/PageSelector'
import CreatePermPopup from '../components/permit/CreatePermPopup';
import TotalAlerts from '../components/home/TotalAlerts'
import CopyLink from '../components/CopyLink'
//action
import {getDateOnly,getTimeOnly, yearMonthDay} from '../actions/actionsDateTime'
import { isHidePermitCreateDate, PERMIT_DEFAULT_TYPE } from '../config';
const CHART_TITLE = [
    {title: 'Item', field: null, flex: 0.2},
    ...isHidePermitCreateDate?[]:[{title: 'Created At', field: 'createDate', flex: 1}],
    {title: 'Name', field: 'name', flex: 4},
    {title: 'Type', field: 'templateType', flex: 1},
    {title: 'Issuer', field: 'createdByUser', flex: 1},
    {title: 'Time Valid', field: '', flex: 1},
    {title: 'Status', field: '', flex: 1},
]

function Screen(props) {
    const hasPerms = props.control.permissions
    // console.log(hasPerms)
    const limit = 10
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [totalItem, setTotalItem] = useState(1)
    const totalPage = Math.ceil(totalItem/limit)||1
    const page =  parseInt(searchParams.get('page'))||1
    const filterName = searchParams.get('name')
    const filterType = searchParams.get('type')
    const filterStatus = searchParams.get('status')
    const filterDate = searchParams.get('date')
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')
    const [permCreatePop, setPermCreatePop] = useState(false)
    // const [realTime, setRealtime] = useState(false)
    const [permitData, setPermitData] = useState([])
    const [deletePop, setDeletePop] = useState(false)
    const [popType, setPopType] = useState('')
    const [deleteObj, setDeleteObj] = useState('')
    const fromRef = useRef(null)
    const toRef = useRef(null)
    useEffect(() => {
        if(!hasPerms.PERMIT_VIEW) return
        let timer = setTimeout(() => fetchPermitData(), 500)
        return () => clearTimeout(timer)
    },[searchParams,props.control])
    async function fetchPermitData(){
        if(!props.control?.projectId) return
        const filter = {}
        const timeFrom = new Date()
        const timeTo = new Date()
        if(filterDate){
            timeFrom.setDate(timeFrom.getDate() - 1)
            if(filterDate === '7 Days') timeFrom.setDate(timeFrom.getDate() - 7)
            if(filterDate === '30 Days') timeFrom.setDate(timeFrom.getDate() - 30)
            filter['createDate'] = {
                "$lte": `${timeTo.toISOString()}`,
                "$gte": `${timeFrom.toISOString()}`
            }
        }
        if(filterType) filter['templateType'] = {"$eq": `${filterType}`}
        if(filterStatus && filterStatus !== 'Valid' && filterStatus !== 'Expired') filter['status'] = {"$eq": `${filterStatus}`}
        if(filterName) filter['name'] = {"$regex": `${filterName}`, "$options":'i'}
        if((filterFrom && filterTo)) filter['createDate'] = {
            "$lte": `${new Date(filterTo).toISOString()}`,
            "$gte": `${new Date(filterFrom).toISOString()}`
        }
        if((!filterFrom && filterTo) || (!filterTo && filterFrom)) return;
        filter["projectId"] = props.control.projectId
        const res = await fetchPermit(props.token.token, filter, {limit,skip:limit*(page-1)})
        if(res.errno) return console.log('error fetching data')
        setTotalItem(res.count)
        setPermitData(res.result)
        // console.log(res.result)
    }
    function startDeletePop(e, obj){
        e.stopPropagation();
        setPopType('delete')
        setDeletePop(true)
        setDeleteObj(obj)
    }
    async function handleDeletePermit(){
        console.log(deleteObj)
        if(deleteObj.attachment?.length > 0){
            deleteObj.attachment.forEach(async ({id, type}, secIdx) => {                
                const res = await deleteFileByName(props.token.token, id+'.'+type.split('/')[1])
            })
        }
        const res = await deletePermit(props.token.token, deleteObj._id)
        if(res.errno) return console.log(res)
        fetchPermitData()
        setDeletePop(false)
    }
    function handleParamChange(key, val){
        const obj = {
            name: filterName,
            type: filterType,
            status: filterStatus,
            date: filterDate,
            from: filterFrom,
            to: filterTo,
        }
        if(key === 'clearDate'){
            searchParams.delete('from')
            searchParams.delete('to')
            fromRef.current.value = ""
            toRef.current.value = ""
            return setSearchParams(searchParams)
        }
        if(key === 'date' && val === 'Select date'){
            setPopType('date')
            setDeletePop(true)
            return;
        }
        if(key === 'from' || key === 'to'){
            searchParams.delete('date')
        }
        if(key === 'date'){
            searchParams.delete('from')
            searchParams.delete('to')
        }
        if(val === 'All' || val === ''){
            searchParams.delete(key)
            return setSearchParams(searchParams)
        }
        // if(obj[key] === val){
        //     searchParams.delete(key)
        //     return setSearchParams(searchParams)
        // }
        if(key === 'page' && (val > totalPage || val < 1)) return;
        searchParams.delete(key)
        if(key !== 'page'){
            searchParams.delete('page')
            searchParams.append('page', 1)
        }
        searchParams.append(key, val)
        setSearchParams(searchParams)
    }
    const handleSelectPage=(num)=>()=>{
        handleParamChange('page', num)
    }
    function handlePermPop(val){
        setPermCreatePop(val)
    }
    function checkIfApprovalTime(obj){
        const from = new Date(obj.validFrom)
        const to = new Date(obj.validTo)
        from.setHours(0)
        to.setHours(24)
        const now = new Date()
        if(from <= now && now <= to) return 'Valid'
        if(now > to) return 'Expired'
        return obj.status
    }
    if(hasPerms.PERMIT_VIEW === false) return (<NoPermssion/>)
    return(
        <div className='w-full h-full bg-[#0C0D14] pb-12' style={{overflowY:'auto'}}>
            {deletePop && 
                <div className='absolute fixed inset-0 w-[100vw] h-[100vh] flex flex-row items-center justify-center' style={{backdropFilter:'blur(3px)', zIndex:999}}>
                    {popType === 'delete' && <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                        <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                            <div className='flex flex-row items-center space-between w-full'>
                                <div className='font-bold'>Confirm Delete</div>          
                            </div>
                            <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => {setDeletePop(false)}}>X</div>         
                            <div className="text-white mt-10 font-bold text-[14px]">Permit Name:</div>
                            <div className='bg-transparent rounded-[99px] text-sm px-[16px] py-[2px] mt-1'  style={{border:'.5px solid #ffffff80'}}>
                                {deleteObj.name}
                            </div>
                            <div onClick={handleDeletePermit} className='button_blue w-full mt-10' style={{padding:'6px 0px'}}>
                                Delete
                            </div>
                        </div>
                    </div>}
                    {popType === 'date' && <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                        <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                            <div className='flex flex-row items-center space-between w-full'>
                                <div className='font-bold'>Select a date range*</div>          
                            </div>
                            <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => {setDeletePop(false)}}>X</div>         
                            <div className='flex flex-row my-4 w-full justify-around'>
                                <div className='w-[50px]'>From:</div>   
                                <input type='date' ref={fromRef} max={filterTo || yearMonthDay(new Date())} onChange={(e) => {handleParamChange('from', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                            </div>
                            <div className='flex flex-row mb-4 w-full justify-around'>
                                <div className='w-[50px]'>To:</div>   
                                <input type='date' ref={toRef} min={filterFrom || undefined} onChange={(e) => {handleParamChange('to', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                            </div>
                            <div className='w-full flex flex-row items-center justify-between gap-2'>
                                <div className='button_blue w-[50%]' onClick={() => {setDeletePop(false)}}>Confirm</div>
                                <div className='button_blue w-[50%] text-white' onClick={() => {handleParamChange('clearDate')}}>Clear</div>
                            </div>
                        </div>
                    </div>}
                </div>
            }
            {permCreatePop && <div className='select-none w-[100vw] h-[100vh] fixed inset-0 flex flex-row items-center justify-center backdrop-blur-sm' style={{zIndex:99}}>
                <div className='w-[100vw] h-[100vh] absolute opacity-40 bg-[#000000]' onClick={() => setPermCreatePop(false)}></div>
                <CreatePermPopup className='' handlePermPop={handlePermPop}/>
            </div>}
            <div style={{...LEFTRIGHT_PADDING}} className='w-full '>
                <div className="flex flex-row mt-[40px] justify-between">
                    <div className='flex flex-row items-center'>
                        <TitleLeftSvg className='mr-[9px]'/>
                        <div className="text-[24px] text-white font-bold ">Permit System</div>
                    </div>
                    {/* <div className='flex flex-row items-center'>
                        <div className='flex flex-row items-center'>
                            <div className='mr-[8px] text-white'>Real time</div>
                            <div onClick={()=>{setRealtime(!realTime)}} className='px-[2px] w-[40px] h-[22px] rounded-full flex flex-row items-center cursor-pointer' style={realTime ? {justifyContent:'flex-end', backgroundColor:'#63BDFF'} : {justifyContent:'flex-start', border: '2px solid #777676'}}>
                                <div className='w-[16px] h-[16px] py-0 bg-white rounded-full' style={realTime ? {backgroundColor:'#00202A'} : {backgroundColor:'#777676'}}></div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="flex flex-row justify-between items-center text-white mt-[47px]">
                    <div className="flex flex-row items-center">
                        <div className="subpage_selected">Permit</div>
                        <div className="subpage_not_selected" onClick={() => navigate('/permit/template')}>Template</div>
                    </div>
                    <div className='flex flex-row items-center gap-4'>
                        <div className='relative dropdown_main select-none cursor-pointer'>
                            <div className="hover:bg-[#888] flex flex-row items-center text-[12px] gap-[8px] min-w-[180px] px-[16px] py-[4px] rounded-[16px] border border-[#aaa] pointer-cursor justify-between" > 
                                {filterDate ? filterDate : filterFrom && filterTo ? `${filterFrom.split('-').join('/')} - ${filterTo.split('-').join('/')}` :'Date'}
                                <DownArrow/>
                            </div>
                            <div className='absolute top-[100%] left-0 dropdown_main_expand flex-col text-xs bg-black w-full gap-[4px] rounded-[4px] border border-[#aaa] max-h-[200px]' style={{overflowY:'auto',zIndex:1}}>
                                {(['All','Today', '7 Days', '30 Days', 'Select date']).map((item,index)=>
                                    <div onClick={() => {handleParamChange('date', item)}} key={index} className='px-[16px] py-[4px] hover:text-black hover:bg-white cursor-pointer'>{item}</div>
                                )}
                            </div>
                        </div>    
                        {hasPerms.PERMIT_ISSUE && <div className='button_blue' onClick={() => setPermCreatePop(true)}><BluePlus className='mr-[2px]' /> Create Permit</div>}                    </div>
                </div>
                <div className='flex flex-row mt-[41px]'>
                    {/* status div */}
                    <div className='w-[455px] h-[300px] mr-[16px]'>
                        <PermitStatusPie />
                    </div>
                    <div className='flex-1 border border-[#FFFFFF80]'>
                        <div className="flex flex-row justify-between items-center text-white px-[24px] py-[17px]" style={{borderBottomWidth: 0.5, borderBottomColor:'#FFFFFF4D'}}>
                            <div className="flex flex-row items-center">
                                <div className="font-bold text-white cursor-pointer flex flex-row items-center gap-2"><SmallHeader />Permit <CopyLink /></div>
                            </div>
                            <div className='flex flex-row items-center gap-[4px]'>
                                <div className='flex flex-row items-center px-[12px] w-[180px] h-[28px] border border-[#aaa] rounded-[16px]'>
                                    <SearchMagGlass className='mr-[8px]'/>
                                    <input onChange={(e) => handleParamChange('name', e.target.value)} className="flex flex-row items-center text-[12px] pointer-cursor bg-transparent h-[18px] my-[0px] outline-none" placeholder='Name' /> 
                                </div>
                                <div className='relative dropdown_main select-none cursor-pointer'>
                                    <div className="hover:bg-[#888] flex flex-row items-center text-[12px] gap-[8px] w-[180px] px-[16px] py-[4px] rounded-[16px] border border-[#aaa] pointer-cursor justify-between" > 
                                        {filterType||'Type'}
                                        <DownArrow/>
                                    </div>
                                    <div className='absolute top-[100%] left-0 dropdown_main_expand flex-col text-xs bg-black w-full gap-[4px] rounded-[4px] border border-[#aaa] max-h-[200px]' style={{overflowY:'auto'}}>
                                        {(['All', ...PERMIT_DEFAULT_TYPE]).map((item,index)=>
                                            <div onClick={() => {handleParamChange('type', item)}} key={index} className='px-[16px] py-[4px] hover:text-black hover:bg-white cursor-pointer'> {item}</div>
                                        )}
                                    </div>
                                </div>                                
                                <div className='relative dropdown_main select-none cursor-pointer'>
                                    <div className="hover:bg-[#888] flex flex-row items-center text-[12px] gap-[8px] w-[180px] px-[16px] py-[4px] rounded-[16px] border border-[#aaa] pointer-cursor justify-between" > 
                                        {filterStatus||'Status'}
                                        <DownArrow/>
                                    </div>
                                    <div className='absolute top-[100%] left-0 dropdown_main_expand flex-col text-xs bg-black w-full gap-[4px] rounded-[4px] border border-[#aaa] max-h-[200px]' style={{overflowY:'auto'}}>
                                        {(['All', 'Draft', 'Pending for approval','Approved', 'Valid', 'Expired', 'Cancelled']).map((item,index)=>
                                            <div onClick={() => {handleParamChange('status', item)}} key={index} className='px-[16px] py-[4px] hover:text-black hover:bg-white cursor-pointer'> {item}</div>
                                        )}
                                    </div>
                                </div>                                
                                {/* <div className='button_blue' onClick={() => {}}><BlueDownloadSvg className='mr-[2px]' />Export</div> */}
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-row text-[14px] py-[22px] px-[16px]">
                                {CHART_TITLE.map((obj, i) => (
                                    <div key={'title'+ obj.title + (i * i)} className="table_header" style={{flex: obj.flex || 1, flexShrink: obj.srink ? 2 : 0}}>
                                        {obj.title}
                                    </div>
                                ))}
                            </div>
                            <div className='px-[16px]'>
                                {permitData.length > 0 && permitData?.map((items, idx) =>{
                                    if(filterStatus === 'Valid'){
                                        if(checkIfApprovalTime(items) === 'Valid' && items.status === 'Approved') {
                                            return (<div key={'TrackP2 Approved' + idx} className="table_row cursor-pointer" onClick={() => {navigate('/permit/create/' + items._id, {state:{data:items, isEdit:true}})}} style={{overflowWrap: 'break-word',backgroundColor: idx%2 ?'#FFFFFF1D':'transparent'}}>
                                            {CHART_TITLE.map((h, i) => 
                                            <div key={'TrackP2' + h.title + i} className="table_item" style={{flex: h.flex}}>{
                                                h.title === 'Actions' ? (<div className='flex flex-row items-center gap-2'>
                                                    <div onClick={() => navigate('/permit/create', {state:{data:items}})}className='bg-[#00202A] py-[2px] px-[16px] rounded-full text-[#5FC5FF] cursor-pointer select-none'>Create Permit</div>
                                                    <BlueThreeDot className='cursor-pointer'/>
                                                </div>):
                                                h.title === 'Created At' ? `${getDateOnly(items[h.field])}`:
                                                h.title === 'Issuer' ? <div className='text-[8px]'>`${items[h.field]?.[0]?.username || ''}`</div>:
                                                h.title === 'Item' ? (idx + 1) + ((page - 1) * limit) :
                                                h.title === 'Time Valid' ? (<div className='text-[10px]'>
                                                    {`${getDateOnly(items.validFrom)}`} <span className='text-[10px] text-[#FFFFFF80]'>to</span><br/>{`${getDateOnly(items.validTo)}`}
                                                </div>):
                                                h.title === 'Status' ? (<div className='flex flex-row items-center justify-between w-full min-w-[128px]'>
                                                    <div className='py-[2px] px-[16px] bg-[#00202A] rounded-full text-[#63BDFF] flex flex-row items-center justify-center select-none'>{items.status === 'Pending for approval' ? 'Pending' : items.status === 'Approved' ? checkIfApprovalTime(items) : items.status}</div>
                                                    {hasPerms.PERMIT_DELETE && <GreyBin onClick={(e) => {startDeletePop(e, items)}} className='cursor-pointer'/>}
                                                </div>)
                                                : <div className="table_item" style={{}}>
                                                    <p className='w-full' style={{wordWrap:'break-word',whiteSpace:'pre-wrap'}}>{items[h.field]}</p>
                                                </div>
                                            }</div>
                                            )}
                                            </div>)
                                        }else{
                                            return <></>
                                        }
                                    }
                                    if(filterStatus === 'Expired'){
                                        if(checkIfApprovalTime(items) === 'Expired' && items.status === 'Approved') {
                                            return (<div key={'TrackP2 Expired' + idx} className="table_row cursor-pointer" onClick={() => {navigate('/permit/create/' + items._id, {state:{data:items, isEdit:true}})}} style={{overflowWrap: 'break-word',backgroundColor: idx%2 ?'#FFFFFF1D':'transparent'}}>
                                            {CHART_TITLE.map((h, i) => 
                                            <div key={'TrackP2' + h.title + i} className="table_item" style={{flex: h.flex}}>{
                                                h.title === 'Actions' ? (<div className='flex flex-row items-center gap-2'>
                                                    <div onClick={() => navigate('/permit/create', {state:{data:items}})}className='bg-[#00202A] py-[2px] px-[16px] rounded-full text-[#5FC5FF] cursor-pointer select-none'>Create Permit</div>
                                                    <BlueThreeDot className='cursor-pointer'/>
                                                </div>):
                                                h.title === 'Created At' ? `${getDateOnly(items[h.field])}`:
                                                h.title === 'Issuer' ? <div className='text-[8px]'>`${items[h.field]?.[0]?.username || ''}`</div>:
                                                h.title === 'Item' ? (idx + 1) + ((page - 1) * limit) :
                                                h.title === 'Time Valid' ? (<div className='text-[10px]'>
                                                    {`${getDateOnly(items.validFrom)}`} <span className='text-[10px] text-[#FFFFFF80]'>to</span><br/>{`${getDateOnly(items.validTo)}`}
                                                </div>):
                                                h.title === 'Status' ? (<div className='flex flex-row items-center justify-between w-full min-w-[128px]'>
                                                    <div className='py-[2px] px-[16px] bg-[#00202A] rounded-full text-[#63BDFF] flex flex-row items-center justify-center select-none'>{items.status === 'Pending for approval' ? 'Pending' : items.status === 'Approved' ? checkIfApprovalTime(items) : items.status}</div>
                                                    {hasPerms.PERMIT_DELETE && <GreyBin onClick={(e) => {startDeletePop(e, items)}} className='cursor-pointer'/>}
                                                </div>)
                                                :  <div className="table_item" style={{}}>
                                                    <p className='w-full' style={{wordWrap:'break-word',whiteSpace:'pre-wrap'}}>{items[h.field]}</p>
                                                </div>
                                            }</div>
                                            )}
                                            </div>)
                                        }else{
                                            return <></>
                                        }
                                    }
                                    return (<div key={'TrackP2' + idx} className="table_row cursor-pointer" onClick={() => {navigate('/permit/create/' + items._id, {state:{data:{}, isEdit:true}})}} style={{overflowWrap: 'break-word',backgroundColor: idx%2 ?'#FFFFFF1D':'transparent'}}>
                                        {CHART_TITLE.map((h, i) => 
                                            <div key={'TrackP2' + h.title + i} className="table_item" style={{flex: h.flex}}>{
                                                h.title === 'Actions' ? (<div className='flex flex-row items-center gap-2'>
                                                    <div onClick={() => navigate('/permit/create', {state:{data:items}})}className='bg-[#00202A] py-[2px] px-[16px] rounded-full text-[#5FC5FF] cursor-pointer select-none'>Create Permit</div>
                                                    <BlueThreeDot className='cursor-pointer'/>
                                                </div>):
                                                h.title === 'Created At' ? `${getDateOnly(items[h.field])}`:
                                                h.title === 'Issuer' ? <div className='text-[10px]'>{items[h.field]?.[0]?.username || ''}</div>:
                                                h.title === 'Item' ? (idx + 1) + ((page - 1) * limit) :
                                                h.title === 'Type' ? <div className='text-[10px]'>{items[h.field]}</div> :
                                                h.title === 'Time Valid' ? (<div className='text-[10px]'>
                                                    {`${getDateOnly(items.validFrom)}`} <span className='text-[10px] text-[#FFFFFF80]'>to</span><br/>{`${getDateOnly(items.validTo)}`}
                                                </div>):
                                                h.title === 'Status' ? (<div className='flex flex-row items-center justify-between w-full min-w-[128px]'>
                                                    <div className='py-[2px] px-[16px] bg-[#00202A] rounded-full text-[#63BDFF] flex flex-row items-center justify-center select-none'>{items.status === 'Pending for approval' ? 'Pending' : items.status === 'Approved' ? checkIfApprovalTime(items) : items.status}</div>
                                                    {hasPerms.PERMIT_DELETE && <GreyBin onClick={(e) => {startDeletePop(e, items)}} className='cursor-pointer'/>}
                                                </div>)
                                                :  <div className="table_item" style={{}}>
                                                    <p className='w-full' style={{wordWrap:'break-word',whiteSpace:'pre-wrap'}}>{items[h.field]}</p>
                                                </div>
                                            }</div>
                                        )}
                                    </div>)}
                                )}
                            </div>
                        </div>
                        <PageSelector page={page} total={totalItem} totalPage={totalPage} handleSelectPage={handleSelectPage}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Screen)
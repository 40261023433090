import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import TotalAlerts from '../components/home/TotalAlerts'
import DropdownMenu from '../components/DropdownMenu'
import Trends from '../components/home/Trends'
import TrendCharts from '../components/charts/TrendCharts';
import LatestAlerts from '../components/home/LatestAlerts'
import ELockEvent from '../components/home/ELockEvent'
import CameraList from '../components/home/CameraList'
import { handleFilterDate, validateTimeRangeSelect,yearMonthDay } from '../actions/actionsDateTime';
//

import SmartWatchAlert from '../components/summary/SmartWatchAlert'
import StatusPie from '../components/summary/StatusPie';
//
import html2canvas from 'html2canvas'
import { jsPDF } from "jspdf";

function Screen(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [showReportPreview,setshowReportPreview]=useState(false)
    const [alertData, setAlertData] = useState()
    const [popType, setPopType] = useState(undefined)
    const [hoverZoom, setHoverZoom] = useState(true)
    const filterDate = (searchParams.get('date') || 'Today')
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')
    const fromRef = useRef(null)
    const toRef = useRef(null)
    function downloadReport() {
        const input = document.getElementById('divToPrint');
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');

            var imgWidth = 210; 
            var pageHeight = 295;  
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;
            var backgroundColor = '#000000'
            var doc = new jsPDF('p', 'mm', 'a4', true);
            //doc.fill="#FFFFFF"
            //doc.rect(0, 0, 10000, 10000, "F");
            var position = 0;

            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight,backgroundColor, undefined, 'FAST');
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                //doc.fill="#FFFFFF"
                //doc.rect(0, 0, 10000, 10000, "F");
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight,backgroundColor, undefined, 'FAST');
                heightLeft -= pageHeight;
            }
            doc.save( 'file.pdf');
        });
    }
    function handleParamChange(key, val){
        searchParams.delete(key)
        if(['from','to'].includes(key)) searchParams.delete('date')
        if(key === 'date'){
            searchParams.delete('from')
            searchParams.delete('to') 
        }
        if(key === 'clearDate') {
            searchParams.delete('from')
            searchParams.delete('to')
            searchParams.delete('date')
            fromRef.current.value = ""
            toRef.current.value = ""
        }
        if(val !== '' && key !== 'clearDate') searchParams.set(key, val)
        setSearchParams(searchParams)
    }
    function handleDateChange(val){
        if(val !== 'Select Date') return handleParamChange('date', val)
        setPopType('date')

    }
    return(
        <div className={'w-full h-full bg-black relative' + (hoverZoom ? ' overflow-hidden' : ' overflow-auto')}>

            <div style={{...LEFTRIGHT_PADDING}} className={'w-full py-[40px]'}>
                <div className="flex flex-row items-center">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold ">Summary</div>

                    <div className='flex flex-1 flex-row items-center text-white justify-end'>
                        <DropdownMenu value={(filterFrom && filterTo ? `${filterFrom} / ${filterTo}` :filterDate)} select = {handleDateChange}/>
                        <div onClick={()=>{setshowReportPreview(true)}} className='button_blue ml-2'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.0013 10.667L4.66797 7.33366L5.6013 6.36699L7.33464 8.10033V2.66699H8.66797V8.10033L10.4013 6.36699L11.3346 7.33366L8.0013 10.667ZM4.0013 13.3337C3.63464 13.3337 3.32075 13.2031 3.05964 12.942C2.79852 12.6809 2.66797 12.367 2.66797 12.0003V10.0003H4.0013V12.0003H12.0013V10.0003H13.3346V12.0003C13.3346 12.367 13.2041 12.6809 12.943 12.942C12.6819 13.2031 12.368 13.3337 12.0013 13.3337H4.0013Z" fill="#63BDFF"/>
                            </svg>
                            Download Report
                        </div>
                    </div>
                </div>
                <div>

                </div>
                <div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                    {/* <div className='rounded-[8px]' style={{flex:5,border:'.5px solid #aaa',padding:24}}>
                        <div className='w-full rounded-[8px]' style={{height:undefined,aspectRatio:11/8,minHeight:'100%',border:'.5px solid #aaa'}}>
                            <Home noPopup={true}/>
                        </div>
                    </div> */}
                    <div className='flex flex-row gap-[24px] w-full' style={{flex:2}}>
                        <div className='w-[50%] max-w-[450px] p-[24px] rounded-[8px]' style={{border:'.5px solid #aaa'}}>
                            {/* <TotalAlerts/> */}
                            <StatusPie />
                        </div>
                        <div className='' style={{width:`calc(100% - min(50%, 450px))`}}>
                            <div onMouseEnter={() => setHoverZoom(true)} onMouseLeave={() => setHoverZoom(false)} className='p-[24px] rounded-[8px] w-[100%]' style={{border:'.5px solid #aaa'}}>
                                <TrendCharts/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                    <div className='w-full p-[24px] rounded-[8px] flex-1' style={{border:'.5px solid #aaa'}}>
                        <SmartWatchAlert />
                    </div>
                </div>
            </div>

            {showReportPreview&&<div className='fixed inset-0 bg-[#00000070] flex justify-center items-center text-white'>
                <div className='bg-[#00000070] relative max-h-[90%] w-[90%] max-w-[1920px] flex flex-col p-[24px] bg-[black] ' style={{border:'.5px solid #ffffff80'}}>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={()=>{setshowReportPreview(false)}}>X</div>         
                    <div>Report Preview</div>
                    <div className='bg-[#00000070] flex-1  mt-10 mb-10' style={{overflowY:'auto',overflowX:'hidden',width: '100%',}}>
                        <div id="divToPrint" className="bg-black flex flex-col justify-start" style={{
                            width: '100%',
                            minHeight: '297px',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}>
                            <div style={{...LEFTRIGHT_PADDING}} className='w-full py-[40px]'>
                                <div className="flex flex-row items-center">
                                    <TitleLeftSvg className='mr-[9px]'/>
                                    <div className="text-[24px] text-white font-bold ">Summary</div>

                                    <div className='flex flex-1 flex-row items-center text-white justify-end'>
                                        {/* <DropdownMenu value={(filterFrom && filterTo ? `${filterFrom} / ${filterTo}` :filterDate)} select = {handleDateChange}/>
                                        <div onClick={()=>{setshowReportPreview(true)}} className='button_blue ml-2'>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.0013 10.667L4.66797 7.33366L5.6013 6.36699L7.33464 8.10033V2.66699H8.66797V8.10033L10.4013 6.36699L11.3346 7.33366L8.0013 10.667ZM4.0013 13.3337C3.63464 13.3337 3.32075 13.2031 3.05964 12.942C2.79852 12.6809 2.66797 12.367 2.66797 12.0003V10.0003H4.0013V12.0003H12.0013V10.0003H13.3346V12.0003C13.3346 12.367 13.2041 12.6809 12.943 12.942C12.6819 13.2031 12.368 13.3337 12.0013 13.3337H4.0013Z" fill="#63BDFF"/>
                                            </svg>
                                            Download Report
                                        </div> */}
                                    </div>
                                </div>
                                <div>

                                </div>
                                <div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                                    {/* <div className='rounded-[8px]' style={{flex:5,border:'.5px solid #aaa',padding:24}}>
                                        <div className='w-full rounded-[8px]' style={{height:undefined,aspectRatio:11/8,minHeight:'100%',border:'.5px solid #aaa'}}>
                                            <Home noPopup={true}/>
                                        </div>
                                    </div> */}
                                    <div className='flex flex-row gap-[24px] w-full' style={{flex:2}}>
                                        <div className='w-[50%] max-w-[450px] p-[24px] rounded-[8px]' style={{border:'.5px solid #aaa'}}>
                                            {/* <TotalAlerts/> */}
                                            <StatusPie alertData={alertData}/>
                                        </div>
                                        <div className='' style={{width:`calc(100% - min(50%, 450px))`}}>
                                            <div className='p-[24px] rounded-[8px] w-[100%]' style={{border:'.5px solid #aaa'}}>
                                                <TrendCharts/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                                    <div className='w-full p-[24px] rounded-[8px] flex-1' style={{border:'.5px solid #aaa'}}>
                                        <SmartWatchAlert isReport={true}/>
                                    </div>
                                </div>
                            </div>
                         </div>
                    </div>
                    <div className='flex flex-rows justify-center'>
                        <div onClick={downloadReport} className='py-[4px] px-[16px] rounded-[99px] flex flex-row items-center gap-[4px] bg-[#00202A] ml-[16px] text-[14px] text-[#63BDFF] cursor-pointer select-none'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.0013 10.667L4.66797 7.33366L5.6013 6.36699L7.33464 8.10033V2.66699H8.66797V8.10033L10.4013 6.36699L11.3346 7.33366L8.0013 10.667ZM4.0013 13.3337C3.63464 13.3337 3.32075 13.2031 3.05964 12.942C2.79852 12.6809 2.66797 12.367 2.66797 12.0003V10.0003H4.0013V12.0003H12.0013V10.0003H13.3346V12.0003C13.3346 12.367 13.2041 12.6809 12.943 12.942C12.6819 13.2031 12.368 13.3337 12.0013 13.3337H4.0013Z" fill="#63BDFF"/>
                            </svg>
                            Download Report
                        </div>
                    </div>
                </div>
            </div>}
            {popType === 'date' && <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Select a date range*</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => {setPopType (false)}}>X</div>         
                    <div className='flex flex-row my-4 w-full justify-around'>
                        <div className='w-[50px]'>From:</div>   
                        <input type='date' ref={fromRef} defaultValue={filterFrom} max={filterTo || yearMonthDay(new Date())} onChange={(e) => {handleParamChange('from', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='flex flex-row mb-4 w-full justify-around'>
                        <div className='w-[50px]'>To:</div>   
                        <input type='date' ref={toRef} defaultValue={filterTo} min={filterFrom || undefined} onChange={(e) => {handleParamChange('to', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='w-full flex flex-row items-center justify-between gap-2'>
                        <div className='button_blue w-[50%]' onClick={() => {setPopType(false)}}>Confirm</div>
                        <div className='button_blue w-[50%] text-white' onClick={() => {handleParamChange('clearDate')}}>Clear</div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Screen)